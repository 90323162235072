<template>
  <v-app id="app">
    <Header></Header>
    <div class="wrapper">
      <router-view></router-view>
    </div>
    <Footer v-if="showFooter"></Footer>
  </v-app>
</template>

<script>
import Header from "@/components/Page/Header.vue";
import Footer from "@/components/Page/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data: function () {
    return {
      showFooter: false,
    };
  },
  methods: {
    toggleFooter() {
      if ((window.location.href.indexOf("explore") !== -1) || window.location.pathname == "/compare") {
        this.showFooter = false;
      } else {
        this.showFooter = true;
      }
    },
  },
  updated() {
    this.toggleFooter();
  },
};
</script>

<style>
.wrapper {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style lang="css">
@import "./style/front.css";
</style>

