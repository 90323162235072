<template>
  <!-- Footer of the website -->
  <v-container
    fluid
    class="px-4"
    style="background-color: #5a5a5a; color: #ffffff"
  >
    <v-row
      no-gutters
      :style="
        $vuetify.breakpoint.xs ? '' : 'padding-left: 10vw ; padding-right: 10vw'
      "
    >
      <v-row no-gutters justify="center">
        <!-- Section which displays credit of te platform-->
        <div class="">
          <p class="font-weight-bold">OCS GE2</p>
          <a
            class="footer-links-item-anchor"
            href="/mentions"
            >Mentions Légales</a
          >
          <br/>
          <a
            class="footer-links-item-anchor"
            href="https://kermap.com"
            target="__blank"
            >Kermap</a
          >
        </div>
      </v-row>
      <v-row no-gutters justify="center">
        <div class="footer-links-item">
          <p class="font-weight-bold">Liens rapides</p>
          <a
            class="footer-links-item-anchor"
            v-for="(item, i) in footerQuickLinkList"
            :key="i"
            :href="item.link"
            >{{ item.title }}</a
          >
        </div>
      </v-row>
      <v-row no-gutters justify="center">
      </v-row>
      <v-row no-gutters justify="center" class="d-none d-md-flex">
        <div class="footer-links-item footer-links-item-logo">
          <v-img
            style="border-radius: 4px"
            max-width="240px"
            max-height="120px"
            :src="require('@/assets/images/gge_footer_logo.png')"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    // links displayed on the footer
    footerQuickLinkList: [
      { title: "À propos", link: "/about" },
      { title: "Explorer", link: "/explore" },
      { title: "Comparer", link: "/compare" },
      { title: "Contact", link: "/contact" },
      { title: "Twitter", link: "https://twitter.com/DataGrandEst" }
    ]
  }),
};
</script>
