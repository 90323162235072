<template>
  <header>
    <div class="header-div d-print-none">
      <a href="/" class="ma-2">
        <v-img contain class="d-none d-md-flex" max-width="15vh" min-width="195px" min-height="70px" max-height="70px" :src="require('@/assets/images/geograndest.svg')">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </a>
      <v-spacer/>
      <div class="header-animate">
        <!-- Navigation button of the header -->
        <a style="color: #747474" :class="path === item.link ? 'selected' : 'header-link'" v-for="(item, i) in menuList" :key="i" :href="item.link">{{ item.title }}</a>
      </div>
      <v-spacer/>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {},
  data: () => ({
    path: null,
    // links displayed on the header
    menuList: [
      { title: "Accueil", link: "/" },
      { title: "Explorer", link: "/explore" },
      { title: "Comparer", link: "/compare" },
      { title: "À propos", link: "/about" },
      { title: "Contact", link: "/contact" },
    ],
  }),
  mounted () {
    var pathname = window.location.pathname
    this.path = pathname
  }
};
</script>

<style>
.header-animate {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}
.header-animate * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.header-animate li {
  display: inline-block;
  list-style: outside none none;
  margin: 0.5em 1.5em;
  padding: 0;
}
.header-animate a {
  padding: 0.4em 0;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  text-decoration: none;
  display: inline-block;
}
.header-animate a:before {
  position: absolute;
  content: '';
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  opacity: 0;
  top: 25%;
  bottom: 25%;
  left: 0;
  right: 0;
  border-top: 3px solid #34495e;
  border-bottom: 3px solid #34495e;
}
.header-animate a:hover,
.header-animate .current a {
  color: #ffffff;
}
.header-animate a:hover:before,
.header-animate .current a:before {
  opacity: 1;
  top: 0;
  bottom: 0;
}

.selected:before {
  opacity: 1 !important;
  top: 0 !important;
  bottom: 0 !important;
}

.selected {
  margin-right: 64px;
  text-decoration: none;
  font-size: 16px
}

</style>