import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/components/Home/Home')
  },
  {
    path: '/explore',
    name: 'Explore',
    component: () => import('@/components/Explore/Explore')
  },
  {
    path: '/explore/:insee',
    name: 'Explore',
    component: () => import('@/components/Explore/Explore')
  },
  {
    path: '/compare',
    name: 'Compare',
    component: () => import('@/components/Compare/Compare')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/components/about/About')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/components/Contact')
  },
  {
    path: '/mentions',
    name: 'Legal',
    component: () => import('@/components/Legal')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
