import Vue from 'vue'
import App from './App.vue'
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
import '@mdi/font/css/materialdesignicons.css'

import Agricole from '@/components/Icons/Agricole';
import Artificialise from '@/components/Icons/Artificialise';
import Eau from '@/components/Icons/Eau';
import Forestier from '@/components/Icons/Forestier';
import ImpermeableBati from '@/components/Icons/ImpermeableBati';
import ImpermeableNonBati from '@/components/Icons/ImpermeableNonBati';
import PermeableUrbain from '@/components/Icons/PermeableUrbain';
import ZoneHumide from '@/components/Icons/ZoneHumide';

import router from './router'


Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    themes: {
      light: {
        red_maxime: '#e3685f',
        grey_maxime: '#e4dbd3',
        red_urba: '#e85444',
        pink_urba: '#ef79c8',
        purple_urba: '#934fd3',
        grey_urba1: 'a6a6a6',
        grey_perma1: '#2f4f4f',
        grey_perma2: '#808080',
        grey_perma3: '#c0c0c0',
        purple_activity1: '#642984',
        purple_activity2: '#9c54df',
        purple_activity3: '#8938b1',
        maroon_activity1: '#6f6b51',
        maroon_activity2: '#907d00',
        purple_activity4: '#a900e6',
        red_activity: '#b15407',
        red_habitat1: '#bb1924',
        orange_habitat1: '#ffa400',
        red_habitat2: '#ee4b3c',
        orange_habitat2: '#fb7f59',
        red_habitat3: '#91283b',
        yellow_habitat: '#eadc8d',
        yellow_agri1: '#ffef44',
        yellow_agri2: '#eed416',
        pink_agri1: '#f4d3ee',
        pink_agri2: '#c36d84',
        pink_agri3: '#f19ab7',
        green_agri: '#b1d62a',
        green_agri2: '#568203',
        green_foret1: '#46a101',
        green_foret2: '#295d30',
        green_foret3: '#2c8629',
        green_foret4: '#7fff00',
        green_foret5: '#8aaa0a',
        green_foret6: '#82c46c',
        green_zh1: '#00b888',
        green_zh2: '#00e6a9',
        blue_eau1: '#00a9e6',
        blue_eau2: '#177bc2',
        blue_eau3: '#335e8f'
      },
    },
  },
  icons: {
    values: {
      agricole: {
        component: Agricole
      },
      artificialise: {
        component: Artificialise
      },
      eau: {
        component: Eau
      },
      forestier: {
        component: Forestier
      },
      impermeable_bati: {
        component: ImpermeableBati
      },
      impermeable_non_bati: {
        component: ImpermeableNonBati
      },
      permeable_urbain: {
        component: PermeableUrbain
      },
      zone_humide: {
        component: ZoneHumide
      }
    }
  }
})

Vue.config.productionTip = false

new Vue({
  vuetify: new Vuetify(),
  router,
  render: h => h(App)
}).$mount('#app')
