<template>
  <svg
    id="Calque_1"
    data-name="Calque 1"
    viewBox="0 0 30 30"
    class="custom-icon"
  >
    <path
      d="M15,10V6H5V24H25V10ZM9,22H7V20H9Zm0-4H7V16H9Zm0-4H7V12H9Zm0-4H7V8H9Zm4,12H11V20h2Zm0-4H11V16h2Zm0-4H11V12h2Zm0-4H11V8h2ZM23,22H15V20h2V18H15V16h2V14H15V12h8Zm-2-8H19v2h2Zm0,4H19v2h2Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Artificialise'
}
</script>

<style scoped>
.custom-icon {
  fill: currentColor;
}
</style>